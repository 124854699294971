'use client'

import React, { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useState } from 'react'
import { SeedSkin, premadeSkins } from '../../prisma/seed-data/skins'
import { Skin } from '@prisma/client'

// ————— types —————

type SkinContextState = {
  skin: Skin | SeedSkin
  setSkin: Dispatch<SetStateAction<Skin | SeedSkin>>
}

// ————— context —————
const defaultState: SkinContextState = {
  skin: premadeSkins[0],
  setSkin: () => {}
}
const SkinContext = createContext<SkinContextState>(defaultState)

// ————— provider —————

export const SkinProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // ————— local state —————

  const [skin, setSkin] = useState<Skin | SeedSkin>(premadeSkins[0])

  // ————— render —————

  return (
    <SkinContext.Provider
      value={{
        skin,
        setSkin
      }}
    >
      {children}
    </SkinContext.Provider>
  )
}

// ————— hooks —————
export const useSkin = () => useContext(SkinContext)
