import { usePathname } from 'next/navigation'
import { createContext, PropsWithChildren, useContext } from 'react'
import { useCallback, useEffect } from 'react'
import mixpanel from './mixpanel'
import { Dict } from 'mixpanel-browser'

const useMixpanel = () => {
  const pathname = usePathname()

  const trackPageView = useCallback(() => {
    mixpanel?.track('PageView', {
      routePath: pathname
    })
  }, [pathname])

  const track = ({ eventName, data }: { eventName: string; data?: Record<string, any> }) => {
    mixpanel?.track(eventName, data)
  }

  // track page view on route change
  useEffect(() => {
    trackPageView()
  }, [trackPageView])

  return {
    trackPageView,
    track,
    getDistinctId: () => mixpanel?.get_distinct_id(),
    alias: (id: string, original?: string) => mixpanel?.alias(id, original),
    identify: (id: string) => mixpanel?.identify(id),
    people: {
      set: (props: Dict) => mixpanel?.people.set(props),
      setOnce: (props: Dict) => mixpanel?.people.set_once(props)
    }
  }
}

type MixpanelContextType = ReturnType<typeof useMixpanel>

const MixpanelContext = createContext<MixpanelContextType | null>(null)

export const useMixpanelContext = () => {
  const context = useContext(MixpanelContext)
  if (!context) {
    throw new Error('useMixpanelContext must be used within a MixpanelProvider')
  }
  return context
}

export const MixpanelProvider = ({ children }: PropsWithChildren) => {
  const mixpanel = useMixpanel()

  return <MixpanelContext.Provider value={mixpanel}>{children}</MixpanelContext.Provider>
}
