import React from 'react'
import ErrorScreen from './ErrorScreen'

type ErrorBoundaryProps = {
  children: React.ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = { hasError: false }
  }
  static getDerivedStateFromError(_error: unknown) {
    return { hasError: true }
  }
  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error('The ErrorBoundary caught an error: ', { error, errorInfo })
  }
  render() {
    if (this.state.hasError) {
      return (
        <ErrorScreen
          text="Something went wrong. Please try again."
          showRetryButton
          onClick={() => this.setState({ hasError: false })}
        />
      )
    }

    // Return children components in case of no error

    return this.props.children
  }
}

export default ErrorBoundary
