import mixpanel from 'mixpanel-browser'

const isEnabled = typeof process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN === 'string'

const inst = isEnabled
  ? mixpanel.init(
      process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN as string,
      {
        ignore_dnt: true,
        api_host: '/mp',
        track_pageview: true
      },
      'slaps'
    )
  : null

if (inst) inst.identify(inst.get_distinct_id())

export default inst
