import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import type { AppRouter } from '@/src/server/routers/_app'
import superjson from 'superjson'

export const nextTrpc = createTRPCNext<AppRouter>({
  config() {
    return {
      transformer: superjson,
      links: [
        httpBatchLink({
          url: `/api/trpc`
        })
      ]
    }
  },
  abortOnUnmount: true,
  ssr: false
})

export const clientTrpc = createTRPCProxyClient<AppRouter>({
  transformer: superjson,
  links: [
    httpBatchLink({
      url: `/api/trpc`
    })
  ]
})
