import type { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import '@/src/styles/globals.css'
import { Toaster } from '@/src/components/ui/sonner'
import SkinBackground from '@/src/components/SkinBackground'
import Layout from '@/src/components/Layout'

import { TracksProvider } from '@/src/context/audio-provider'
import { SkinProvider } from '@/src/context/skin-provider'
import { MixpanelProvider } from '@/src/lib/mixpanel/useMixpanel'
import { Source_Code_Pro } from 'next/font/google'
import ErrorBoundary from '@/src/components/ErrorBoundary'
import Script from 'next/script'

import { Suspense } from 'react'
import { nextTrpc } from '../lib/trpc'

const sourceCodePro = Source_Code_Pro({ subsets: ['latin'] })

function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${sourceCodePro.style.fontFamily};
        }
      `}</style>
      <ErrorBoundary>
        <SessionProvider session={session}>
          <MixpanelProvider>
            <SkinProvider>
              <TracksProvider>
                <Layout>
                  <SkinBackground />
                  <Suspense>
                    <Component {...pageProps} />
                  </Suspense>
                </Layout>
              </TracksProvider>
              <Toaster />
            </SkinProvider>
          </MixpanelProvider>
        </SessionProvider>
      </ErrorBoundary>
      <Script async src="https://tally.so/widgets/embed.js"></Script>
    </>
  )
}

export default nextTrpc.withTRPC(App)
