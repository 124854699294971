'use client'

import React, { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import * as Tone from 'tone'
import { Howl } from 'howler'

import { useBpmStore } from '../lib/bpmStore'
import { usePlayerStore } from '../lib/playerStore'
import { transposeTrack } from '../lib/utils'

// ————— types —————

export type SongData = {
  audio: string
  downbeat_times: number[]
  sample_rate: number
  text_prompt: string
  bpm: number
  key: string
  debug:
    | {
        downbeat_times: number[]
        end_time: number
        initial_duration: number
        start_time: number
        stretch_rate: number | null
      }
    | {}
  duration: number
}

export type Track = {
  id: string
  src: string
  player: Howl
  data: SongData[]
  title: string
  isSoloed?: boolean
  isMuted?: boolean
  pitchShiftNode?: Tone.PitchShift
}

type TracksContextState = {
  tracks: Track[]
  setTracks: Dispatch<React.SetStateAction<Track[]>>
  playAll: () => void
  pauseAll: () => void
  seekAll: (time: number) => void
  stopAll: () => void
  toggleMuteTrack: (track: Track) => void
  toggleSoloTrack: (track: Track) => void
  getPlayer: (src: string) => Howl
  addNewTrack: (songData: SongData[], title: string) => Promise<Track | void>
  deleteTrack: (track: Track) => void
  updateBpm: (nextBpm: number, isReset?: boolean) => Promise<void>
  updateKey: (prevKey: string, nextKey: string) => Promise<void>
  lockedDuration?: number
  setLockedDuration: Dispatch<SetStateAction<number | undefined>>
  allTracksLoaded: boolean
  setAllTracksLoaded: Dispatch<SetStateAction<boolean>>
}

// ————— context —————
const defaultState: TracksContextState = {
  tracks: [],
  setTracks: () => {},
  playAll: () => {},
  pauseAll: () => {},
  seekAll: () => {},
  stopAll: () => {},
  toggleMuteTrack: () => {},
  toggleSoloTrack: () => {},
  getPlayer: () => new Howl({ src: [] }),
  addNewTrack: async (_songData: SongData[], _title: string) => {},
  deleteTrack: () => {},
  updateBpm: async () => {},
  updateKey: async () => {},
  lockedDuration: undefined,
  setLockedDuration: () => {},
  allTracksLoaded: false,
  setAllTracksLoaded: () => {}
}
const TracksContext = createContext<TracksContextState>(defaultState)

// ————— provider —————

export const TracksProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // ————— local state —————

  const getBpmState = useBpmStore.getState
  const setIsPlaying = usePlayerStore((state) => state.setIsPlaying)
  const [tracks, setTracks] = useState<Track[]>([])
  const [lockedDuration, setLockedDuration] = useState<number>()
  const [allTracksLoaded, setAllTracksLoaded] = useState(false)

  // ————— effects —————

  useEffect(() => {
    const anySoloed = tracks.some((t) => t.isSoloed)
    tracks.forEach((track) => {
      if (anySoloed) {
        // If any track is soloed, check for both solo and mute states
        if (!!track.isSoloed) {
          if (!!track.isMuted) {
            track.player.mute(true)
          } else {
            track.player.mute(false)
          }
        } else {
          track.player.mute(true)
        }
      } else {
        // No tracks are soloed, respect each track's individual mute state
        if (!!track.isMuted) {
          track.player.mute(true)
        } else {
          track.player.mute(false)
        }
      }
    })
  }, [tracks])

  const updateBpm = async (currentBpm: number) => {
    tracks.forEach((track) => track.player.rate(currentBpm / getBpmState().bpm)) // getBpmState always gives us the most up to date value
  }

  const updateKey = async (currentKey: string, nextKey: string) => {
    tracks.forEach((track) => transposeTrack(track, currentKey, nextKey))
  }

  // ————— controls for all tracks —————

  const playAll = () => {
    tracks.forEach((track) => {
      track.player.play()
    })
    setIsPlaying(true)
  }

  const pauseAll = () => {
    tracks.forEach((track) => track.player.pause())
    setIsPlaying(false)
  }

  const seekAll = (time: number) => {
    // time in seconds
    tracks.forEach((track) => track.player.seek(time))
  }

  const stopAll = () => {
    tracks.forEach((track) => track.player.stop())
    setIsPlaying(false)
  }

  // ————— controls for individual tracks —————

  const deleteTrack = (track: Track) => {
    track.player.stop()
    track.player.unload()
    // @ts-ignore
    track.player = null
    // @ts-ignore
    delete track.player
    if (track.pitchShiftNode) track.pitchShiftNode.dispose()
    setTracks((prevTracks) => prevTracks.filter((t) => t.id !== track.id))
  }

  const toggleMuteTrack = (track: Track) => {
    // update track object only, handle actual playback adjustments above in useEffect
    setTracks((prev) => prev.map((t) => (t.id === track.id ? { ...t, isMuted: !t.isMuted } : t)))
  }

  const toggleSoloTrack = (track: Track) => {
    // update track object only, handle actual playback adjustments above in useEffect
    setTracks((prev) => prev.map((t) => (t.id === track.id ? { ...t, isSoloed: !t.isSoloed } : t)))
  }

  const getPlayer = (src: string) =>
    new Howl({
      src: [src],
      loop: true,
      volume: 0.5,
      onload: function () {
        setAllTracksLoaded(tracks.every((t) => t.player.state() === 'loaded'))
      }
    })

  const addNewTrack = async (songData: SongData[], title: string): Promise<Track | void> => {
    stopAll()
    setIsPlaying(false)

    const newTrack = {
      id: v4(),
      src: songData[0].audio,
      data: songData,
      player: getPlayer(songData[0].audio),
      title
    }
    setTracks((prev) => [...prev, newTrack])

    seekAll(0)
    // returned for mixpanel tracking
    return newTrack
  }

  // ————— render —————

  return (
    <TracksContext.Provider
      value={{
        tracks,
        setTracks,
        playAll,
        pauseAll,
        seekAll,
        stopAll,
        deleteTrack,
        toggleMuteTrack,
        toggleSoloTrack,
        getPlayer,
        addNewTrack,
        updateBpm,
        updateKey,
        lockedDuration,
        setLockedDuration,
        allTracksLoaded,
        setAllTracksLoaded
      }}
    >
      {children}
    </TracksContext.Provider>
  )
}

// ————— hooks —————
export const useTracks = () => useContext(TracksContext)
