import { create } from 'zustand'

interface BpmState {
  bpm: number
  currentBpm: number | null
  setBpm: (newBpm: number) => void
  setCurrentBpm: (newCurrentBpm: number) => void
}

export const useBpmStore = create<BpmState>((set) => ({
  bpm: 120,
  currentBpm: null,
  setBpm: (newBpm) => set({ bpm: newBpm }),
  setCurrentBpm: (newCurrentBpm) => set({ currentBpm: newCurrentBpm })
}))
